h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-style: italic;
  span {
    font-weight: $font-weight-bold;
    font-family: $font-family-sans-serif;
    font-style: normal;
  }
}
.cms-text {
  margin-top: 1.5rem;
  word-break: break-word;
  color: $gray-900;
  letter-spacing: $letter-spacing;

  p,
  h1,
  h2,
  h3,
  h4,
  ul,
  ol,
  table,
  a {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    color: $gray-900;
    font-size: $font-size-base;
    line-height: 2;
  }

}

[class*='display-'] {
  line-height: 1;
}
