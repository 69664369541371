main#page-home {

  $headerHeightXS: 11.25rem;
  $triangleHeightXS: 4rem;

  #banner {
    img {
      z-index: 1;
    }
    .overlay {
      z-index: 2;
    }
    .triangle {
      z-index: 3;
      @include media-breakpoint-down(md) {
        height: $triangleHeightXS;
        width: 100%;
      }
    }
    .caption {
      z-index: 5;
      padding: ($headerHeightXS + 4rem) 0 (($triangleHeightXS * 2) + 4rem);

      @include media-breakpoint-up(md) {
        width: 100%;
        height: 100%;
        padding: 0;
      }
    }
    @include media-breakpoint-up(md) {
      height: 100vh;
    }
    + div {
      z-index: 100;
    }
  }

  #about {
    @include media-breakpoint-down(sm) {
      overflow: hidden;
    }
  }

  #usp {
    z-index: 10;
    .c-service {
      .card-text {
        display: none;
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }
    .triangle {
      z-index: -1;
      @include media-breakpoint-down(md) {
        max-height: unset;
        height: 90%;
      }
    }
  }

  #steps {
    li {
      .list-group-item {
        border-radius: $border-radius-pill;
        border: 0;
        .badge {
          width: 2.5rem;
        }
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 5%;
        left: 0;
        height: 30%;
        width: 100%;
        box-shadow: 0 3px 78px 0 rgba($darker, 0.4);
      }
      + li {
        margin-top: 1.25rem;
      }
    }
  }

}
