$anim-speed: 0.25s;
$easing-in: ease-out;
$easing-out: ease-in;

.a-hover-fade-img {
  pointer-events: none;

  img {
    pointer-events: auto;
    opacity: 1;
    transition: opacity $anim-speed $easing-in;
  }

  &:hover {
    img:not(:hover) {
      opacity: 0.5;
      transition-timing-function: $easing-out;
    }
  }
}
