textarea.form-control {
  min-height: 10rem;
}

.form-check-label {
  font-family: $font-family-sans-serif;
  letter-spacing: $letter-spacing;
  font-weight: $form-label-font-weight;
  font-size: $form-label-font-size;
  a {
    color: $form-label-color;
  }
}

.form-floating {

  > .form-control:focus ~ label,
  > .form-control:not(:placeholder-shown) ~ label,
  > .form-control-plaintext ~ label,
  > .form-control ~ label,
  > .form-select ~ label {
    color: $form-label-color;
    font-weight: $form-label-font-weight;
    letter-spacing: $letter-spacing;
    font-family: $font-family-sans-serif;
    font-size: $form-label-font-size;
  }

}

.validation-feedback.is-invalid {
  input:not(:valid),
  textarea:not(:valid) {
    border-color: $danger;
  }
  .invalid-feedback {
    display: block;
  }
}

.was-validated {

  .form-check-input,
  .form-select,
  .form-control {
    &:valid,
    &.valid {
      border-color: $input-border-color;
      box-shadow: none;
      &:focus {
        border-color: $input-border-color;
        box-shadow: none;
      }
      &:checked {
        background-color: $form-check-input-bg;
      }
      & ~ .form-check-label {
        color: $form-label-color;
      }
    }
    &:invalid,
    &.is-invalid {
      border-color: $form-feedback-invalid-color;
      box-shadow: none;
      &:focus {
        border-color: $form-feedback-invalid-color;
        box-shadow: none;
      }
    }
  }
}

.valid-feedback,
.invalid-feedback {
  flex: 1 1 100%;
  font-weight: $font-weight-semibold;
}

fieldset {
  &.is-invalid {
    > p {
      color: $danger;
    }
  }
}

.form-check {
  padding-left: $form-check-input-width + 0.938rem;
  .form-check-input {
    margin-left: -$form-check-input-width - 0.938rem;
    &:checked[type=checkbox] {
      background-size: $form-check-input-width * 0.5 $form-check-input-width * 0.5;
    }
  }
}

form {
  button[type='submit'] {
    @include media-breakpoint-only(xs) {
      width: 100%;
    }
  }
}

.custom-file-button {
  input[type='file'],
  label {
    padding: $form-floating-padding-y $form-floating-padding-x;
  }
  input[type="file"] {
    margin-left: -2px !important;
    &::-webkit-file-upload-button {
      display: none;
    }
    &::file-selector-button {
      display: none;
    }
  }

  label {
    font-weight: 500;
    color: $white;
    font-family: $font-family-sans-serif;
    letter-spacing: $letter-spacing;
  }

  &:hover {
    label {
      cursor: pointer;
    }
  }
}

.form-text {
  margin-top: 0.625rem;
  color: $primary;
  letter-spacing: $letter-spacing;
  font-family: $font-family-sans-serif;
  font-weight: 500;
  font-size: 1rem;
}
