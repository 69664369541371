// Settings
// ==================================================
$hamburger-padding-x           : 0.5rem;
$hamburger-padding-y           : 0.5rem;
$hamburger-layer-width         : 24px;
$hamburger-layer-height        : 1.5px;
$hamburger-layer-spacing       : 4px;
$hamburger-layer-color         : #000;
$hamburger-layer-border-radius : 4px;
$hamburger-hover-opacity       : 1;
$hamburger-active-layer-color  : $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false;
$hamburger-hover-filter       : opacity(50%);
$hamburger-active-hover-filter: $hamburger-hover-filter;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
  3dx,
  3dx-r,
  3dy,
  3dy-r,
  3dxy,
  3dxy-r,
  arrow,
  arrow-r,
  arrowalt,
  arrowalt-r,
  arrowturn,
  arrowturn-r,
  boring,
  collapse,
  collapse-r,
  elastic,
  elastic-r,
  emphatic,
  emphatic-r,
  minus,
  slider,
  slider-r,
  spin,
  spin-r,
  spring,
  spring-r,
  stand,
  stand-r,
  squeeze,
  vortex,
  vortex-r
);
