.l-site-clients {

  $size: 5rem;
  $gutterX: 2.5rem;
  $gutterY: 2.5rem;

  .scroller {

    height: calc($size + ($gutterY * 2));
    max-width: 100%;
    &__wrapper {
      height: 100%;
      width: 200vw;
      animation: scrollText 60s linear infinite;
    }
  }

  .card {
    padding: $gutterY $gutterX;
    img {
      width: auto;
      height: $size;
      mix-blend-mode: multiply;
    }
  }


}
