.l-site-nav {

  $nav-breakpoint: 'lg';
  $offcanvas-width: 18.75rem;
  $offcanvas-top: 0; // could also be navbar height
  $offcanvas-bg-color: $primary;
  $offcanvas-y: 1.25rem;
  $offcanvas-nav-link-padding-x: 0.625rem;
  $offcanvas-nav-link-padding-y: 1.625rem;
  $offcanvas-active-bg: $primary;

  position: relative;
  z-index: $zindex-offcanvas;

  .navbar-brand {
    img {
      height: 5rem;
      max-width: 33vw;
      @include media-breakpoint-up(sm) {
        height: 6.25rem;
      }
    }
  }

  .offcanvas {

    border-left: 0;

    .btn-close {
      @include media-breakpoint-up($nav-breakpoint) {
        display: none;
      }
    }
    .nav-link {
      font-family: $font-family-sans-serif;
      font-size: 1.125rem;
      font-weight: $font-weight-bold;
      letter-spacing: $letter-spacing;
      line-height: 1.5;
      span {
        display: block;
        font-size: 0.625rem;
        text-transform: uppercase;
      }
      &.active,
      &:hover,
      &:focus {
        color: $nav-link-color;
        span {
          color: $nav-link-hover-color;
        }
      }
    }
    .secondary-nav {
      @include media-breakpoint-down($nav-breakpoint) {
        .nav-link {
          border: 0;
          padding: 0.625rem 1.25rem;
          @include font-size(12px);
          text-transform: uppercase;
          letter-spacing: $letter-spacing;
          font-weight: $font-weight-semibold;
          transition: $transition-base;
          &:hover,
          &:focus,
          &.active {
            padding: 0.625rem 1.25rem 0.625rem 2.5rem;
          }
        }
        ul:not(.nav-legal){
          border-bottom: solid 1px rgba($white, 0.25);
        }
        ul.nav-legal {
          opacity: 0.5;
        }
      }
    }

    @include media-breakpoint-up($nav-breakpoint) {
      position: static;
      visibility: visible !important;
      background: none;
      border: 0;
      transform: none;
      width: auto;
      margin-left: auto;
    }

    @include media-breakpoint-down($nav-breakpoint) {
      width: $offcanvas-width;
      background-color: $offcanvas-bg-color;
      padding-top: $offcanvas-y;
      padding-bottom: $offcanvas-y;
      .nav-link {
        color: $navbar-dark-hover-color;
        padding: 1.25rem;
        border-bottom: solid 1px rgba($white, 0.25);
        transition: $transition-base;
        &:hover,
        &:focus,
        &.active {
          padding-left: 2.5rem;
          color: $navbar-dark-hover-color;
          span {
            color: $navbar-dark-hover-color;
          }
        }
      }
    }
  }

  .hamburger {
    $size: 44px;
    height: $size;
    min-width: $size;
    border-radius: $size * 0.5;
    display: flex;
  }

  &:not(.navbar-dark) {

    background-color: $lighter;

    .hamburger {
      border: solid 1px $darker;
      .hamburger-inner {
        &,
        &:before,
        &:after {
          background: $darker;
        }
      }
    }

  }

  &.navbar-dark {

    .nav-link {
      &.active,
      &:hover,
      &:focus {
        color: $white;
      }
    }

    .hamburger {
      border: solid 1px $white;
      .hamburger-inner {
        &,
        &:before,
        &:after {
          background: $white;
        }
      }
    }

  }

  #top-bar {
    .nav-link,
    span {
      display: block;
      text-transform: uppercase;
      font-size: 0.6875rem;
      font-family: $font-family-sans-serif;
      font-weight: $font-weight-bold;
      color: $nav-link-color;
      letter-spacing: $letter-spacing;
      padding: 12px 20px;
    }
    .nav-link.active,
    .nav-link:hover,
    .nav-link:focus {
      color: $nav-link-hover-color;
    }
    .external-link {
      display: block;
      height: 100%;
      min-width: 104px;
      img {
        bottom: -2rem;
        transition: $transition-base;
      }
      &:before {
        content: '';
        display: block;
        background-color: $red;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
      }
      &:hover,
      &:focus {
        img {
          bottom: -2.5rem;
        }
      }
    }
  }

}
