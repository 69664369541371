.l-widget.l-widget--image-text {
  img {
    border-radius: $border-radius;
    @include media-breakpoint-between(md,xxl) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  [class*='row-reverse'] {
    img {
      @include media-breakpoint-between(md,xxl) {
        border-radius: $border-radius;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
