#widget-container {
  .l-widget {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    @include media-breakpoint-up(md) {
      padding-top: 6.25rem;
      padding-bottom: 6.25rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 7.5rem;
      padding-bottom: 7.5rem;
    }

    &--two-images + .l-widget.l-widget--two-images {
      margin-top: -3.5rem;
      @include media-breakpoint-up(sm) {
        margin-top: -3.5rem;
      }
      @include media-breakpoint-up(md) {
        margin-top: -6.5rem;
      }
      @include media-breakpoint-up(lg) {
        margin-top: -7.5rem;
      }
    }

    &.bg-body {
      + .bg-body {
        padding-top: 0;
      }
    }
    &.bg-lighter {
      + .bg-lighter {
        padding-top: 0;
      }
    }

    &.bg-lighter:first-child {
      padding-top: 0;
    }

  }

}
