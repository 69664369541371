.l-widget.l-widget--two-patches {
  .card {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 1.875rem;
      bottom: 0.9375rem;
      left: 1.875rem;;
      height: 30%;
      box-shadow: 0 3px 78px 0 rgba($darker, 0.4);
    }
  }
}
