@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

// Custom
$container-fluid-max-width: 100rem;
$z-index-cookies: 10000;
$letter-spacing: 0.0625rem;

// Options
$enable-negative-margins: true;
$enable-validation-icons: false;

// Colors
$blue-100: #E5EFF5;
$blue-300: #BCD5E8;
$blue-600: #8DBCD3;
$blue-900: #43778F;

$gray-900: #161414;

$primary: $blue-900;
$green: #198754;
$red: #F1625C;

$lighter: $blue-100;
$light: $blue-300;
$dark: $blue-600;
$darker: $blue-900;
$danger: $red;

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":  $primary,
  "success":  $success,
  "danger":   $danger,
  "lighter":  $lighter,
  "light":    $light,
  "dark":     $dark,
  "darker":   $darker
);

// Spacing
$spacer: 0.625rem;
$spacers: (
  0: 0,
  1: $spacer,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
  7: $spacer * 7,
  8: $spacer * 8,
  9: $spacer * 9,
  10: $spacer * 10,
  12: $spacer * 12,
  15: $spacer * 15,
);

// Paragraphs
$paragraph-margin-bottom: 1.25rem;

// Grid columns
$grid-gutter-width: 2.5rem;
$container-padding-x: $grid-gutter-width * 0.625;
$gutters: $spacers;

// Components
$border-radius:               0.625rem;
$border-radius-sm:            0.313rem;
$border-radius-lg:            1.25rem;
$border-radius-xl:            2.5rem;

$box-shadow: none;

// Typography
$font-family-sans-serif: "brandon-grotesque", sans-serif;
$font-family-base: "roboto", sans-serif;
$font-family-serif: 'Arapey', serif;
$font-size-base: 0.875rem;
$font-weight-base: 500;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-semibold:        500;
$font-weight-bold:            600;
$font-weight-bolder:          700;

$h1-font-size:                $font-size-base * 2.86;
$h2-font-size:                $font-size-base * 2.14;
$h3-font-size:                $font-size-base * 1.43;
$h4-font-size:                $font-size-base * 1.14;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base * 0.71;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  12: 0.75rem,
  14: 0.875rem,
  16: 1rem,
  18: 1.125rem,
  20: 1.25rem,
  24: 1.5rem,
  30: 1.875rem
);

$headings-margin-bottom: $spacer * 2.5;
$headings-font-family: "Arapey", sans-serif;
$headings-line-height: 1.25;
$headings-font-weight: 400;
$headings-color: $primary;

$display-font-sizes: (
  1: 3.75rem,
  2: 3.125rem,
  3: 2.5rem,
  4: 1.875rem
);
$display-font-family: $headings-font-family;
$display-font-weight: $headings-font-weight;
$display-line-height: $headings-line-height;

// Forms & Buttons
$input-btn-padding-y: 1.25rem;
$input-btn-padding-x: 1.25rem;
$input-btn-font-family: $font-family-sans-serif;
$input-btn-font-size: 1rem;
$input-btn-line-height: 1.3;

$input-btn-focus-box-shadow: none;

// Buttons
$btn-padding-y: 0.625rem;
$btn-padding-x: 1.25rem;
$btn-font-family: $font-family-sans-serif;
$btn-font-size: 1rem;
$btn-line-height: 1.5;

$btn-border-width: 0;

$btn-font-weight: 700;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

$btn-border-radius: $border-radius-pill;

// Forms
$form-label-margin-bottom:              .5rem;
$form-label-font-size:                  1rem;
$form-label-font-style:                 normal;
$form-label-font-weight:                $font-weight-bold;
$form-label-color:                      $primary;

$input-padding-x: 1.25rem;
$input-padding-y: 0.625rem;

$input-color: $body-color;
$input-border-color: $body-bg;
$input-border-width: 1px;
$input-box-shadow: none;

$input-border-radius: $border-radius-sm;

$input-focus-border-color: $light;
$input-focus-color: $body-color;
$input-focus-box-shadow: none;

$form-check-input-width: 1.25rem;
$form-check-label-color: $primary;

$form-check-input-bg: $input-bg;
$form-check-input-border: $input-border-color;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: none;

$form-check-input-checked-color: $primary;
$form-check-input-checked-bg-color: $form-check-input-bg;
$form-check-input-checked-border-color: $white;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 14 10.4'%3E%3Cpolyline fill='none' stroke='#{$form-check-input-checked-color}' stroke-width='2' stroke-linecap='round' points='1,5 5,9 13,1 '/></svg>");

$input-group-addon-bg: $primary;

$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-color: $input-color;
$form-select-border-color: $input-border-color;
$form-select-indicator-color: $primary;
$form-select-bg-position: right $form-select-padding-x top calc(50% + 4px);
$form-select-bg-size: 16px 16px;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 31'%3E%3Cpolyline fill='none' stroke='#{$form-select-indicator-color}' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' points='1.5,1.5 15.5,11.5 29.5,1.5'/></svg>");
$form-select-box-shadow: none;

$form-select-focus-border-color: $input-focus-border-color;
$form-select-focus-box-shadow: none;

$form-file-button-color:          $primary;
$form-file-button-bg:             $input-group-addon-bg;
$form-file-button-hover-bg:       shade-color($form-file-button-bg, 5%);

$form-floating-height:            add(3.75rem, $input-height-border);
$form-floating-line-height:       1.25;
$form-floating-padding-x:         1.25rem;
$form-floating-padding-y:         1.25rem;
$form-floating-input-padding-t:   1.8rem;
$form-floating-input-padding-b:   .625rem;
$form-floating-label-opacity:     1;
$form-floating-label-transform:   scale(.85) translateY(-.5rem) translateX(0.25rem);
$form-floating-transition:        opacity .1s ease-in-out, transform .1s ease-in-out;

// Navs
$nav-link-padding-y: 1.875rem;
$nav-link-padding-x: 1.563rem;
$nav-link-color: $primary;
$nav-link-hover-color: $red;
$nav-link-disabled-color: $light;

// Navbar
$navbar-nav-link-padding-y: 1.875rem;
$navbar-nav-link-padding-x: 1.563rem;

$navbar-dark-color: $white;
$navbar-light-color: $primary;

$navbar-dark-color: $white;
$navbar-dark-hover-color: $white;
$navbar-dark-active-color: $white;

// Dropdowns
$dropdown-padding-x: 0.875rem;
$dropdown-padding-y: 0.625rem;
$dropdown-spacer: 0.625rem;
$dropdown-font-size: 1rem;
$dropdown-color: $white;
$dropdown-bg: $primary;
$dropdown-border-color: $primary;
$dropdown-border-radius: $border-radius-lg;
$dropdown-border-width: 1px;

$dropdown-box-shadow: none;

$dropdown-link-color: $white;
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: transparent;

$dropdown-link-active-color: $primary;
$dropdown-link-active-bg: transparent;

$dropdown-item-padding-y: 0.3125rem;
$dropdown-item-padding-x: 1.25rem;

// Cards
$card-spacer-y:                     4.375rem;
$card-spacer-x:                     2.5rem;
$card-title-spacer-y:               $spacer * .5;
$card-border-width:                 1px;
$card-border-color:                 $light;
$card-border-radius:                $border-radius;
$card-color:                        $primary;
$card-bg:                           $white;

// List group
$list-group-color:          $primary;
$list-group-bg:             $white;
$list-group-border-color:   $light;
$list-group-border-radius: $border-radius;

$list-group-item-padding-y:      0.9375rem;
$list-group-item-padding-x:      0.9375rem;

// Offcanvas
$offcanvas-box-shadow: none;
$offcanvas-backdrop-bg: $lighter;
$offcanvas-backdrop-opacity: 0.5;

// Image thumbnails
$thumbnail-border-radius: $border-radius;

// Modals
$modal-backdrop-bg:                 $light;
$modal-backdrop-opacity:            .9;

// Validation
$form-feedback-margin-top:          0.625rem;
$form-feedback-font-size:           $form-text-font-size;
$form-feedback-font-style:          $form-text-font-style;
$form-feedback-valid-color:         $success;
$form-feedback-invalid-color:       $danger;

$form-feedback-icon-valid-color:    $form-feedback-valid-color;
$form-feedback-icon-valid:          none;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
$form-feedback-icon-invalid:        none;
