* {
  @include font-smoothing(on);

  &::selection {
    color: $body-bg;
    background: lighten($primary, 10%);
  }
}

html {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100%;
}

body {
  word-break: break-word;
}

main {
  flex-shrink: 0;
}

.container-fluid {
  max-width: $container-fluid-max-width;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @include media-breakpoint-up(sm) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.bg-none {
  background: none !important;
}

.triangle {
  height: 30vh;
  max-height: 300px;
  width: 100%;
}

.wave-wrapper {
  svg {
    stroke-width: 3;
    + svg {
      margin-top: 6px;
    }
  }
  &.top {
    top: 40px;
    @include media-breakpoint-up(sm) {
      top: 70px;
    }
  }
  &.right {
    right: 0;
    transform: translateX(50%);
  }
  &.bottom {
    bottom: 40px;
    @include media-breakpoint-up(sm) {
      bottom: 70px;
    }
  }
  &.left {
    left: 0;
    transform: translateX(-50%);
  }
}

pre {
  overflow: visible;
}

abbr[title] {
  text-decoration: none;
}

.icon--wave {
  stroke-width: 3;
}
