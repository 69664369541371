.l-site-instagram {

  $size: 25vw;
  $gap: 4px;

  max-height: calc($size + ($size * 0.5) + $gap);

  .img-rows {
    overflow: hidden;

    .img-row {
      display: flex;
      gap: $gap;
      transform: translateX(calc($gap * 0.5));

      &:nth-child(odd) {
        transform: translateX(calc(($size * 0.5) * -1));
      }

      &:not(:first-child) {
        margin-top: calc((($size * 0.5) * -1) + $gap );
      }

      a {
        position: relative;
        flex: 1;
        overflow: hidden;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        flex-basis: $size;
        flex-shrink: 0;
        border-radius: $border-radius-sm;

        img {
          aspect-ratio: 1/1;
          object-fit: cover;
          display: block;
          max-width: 100%;
          height: auto;
        }

      }


    }

  }

}
