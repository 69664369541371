main#page-contact {

  .shop-item {
    address {
      a,
      span {
        display: block;
        padding: 0.25rem 0;
        color: $body-color;
        letter-spacing: $letter-spacing;
        text-decoration: none;
      }
    }
  }

  #map {
    height: 50vh;
    max-height: 450px;
  }

}
