.c-custom-button-toggle {
  display: flex;
  a {
    background-color: $white;
    color: $primary;
    font-family: $font-family-sans-serif;
    letter-spacing: $letter-spacing;
    font-weight: $font-weight-bold;
    text-decoration: none;
    padding: $btn-padding-y $btn-padding-x;
    min-width: 7.5rem;
    width: 50%;
    @include media-breakpoint-up(md) {
      width: auto;
    }
    &:hover,
    &.active,
    &:focus,
    &:active {
      color: $white;
    }
    &.active,
    &:focus,
    &:active {
      background-color: $primary;
    }
    &:hover {
      background-color: $light;
    }
  }
}
