.c-service {

  $iconSize: 4rem;

  text-decoration: none;
  text-align: center;
  border: 0;
  background-color: transparent;

  .card-icon {
    border: solid $card-border-width $card-border-color;
    width: $iconSize * 1.25;
    height: $iconSize * 1.25;
    border-radius: $iconSize * 1.25;
    background-color: $card-bg;
    z-index: 2;
    @include media-breakpoint-up(sm) {
      width: $iconSize * 1.5;
      height: $iconSize * 1.5;
      border-radius: $iconSize;
    }
  }
  .card-body {
    background-color: $card-bg;
    border-radius: $card-border-radius;
    margin-top: -($iconSize * 0.5);
    @include media-breakpoint-up(sm) {
      margin-top: -($iconSize);
      padding-top: $card-spacer-x + $iconSize;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 1.875rem;
      bottom: 0.9375rem;
      left: 1.875rem;;
      height: 30%;
      box-shadow: 0 3px 78px 0 rgba($darker, 0.4);
      z-index: -1;
    }
  }
  .card-text {
    line-height: 2;
    letter-spacing: $letter-spacing;
  }
  .card-title {
    span {
      @include media-breakpoint-up(md) {
        display: block;
      }
      @include media-breakpoint-up(lg) {
        display: inline-block;
      }
    }
  }
  .icon--wave {
    stroke-width: 2;
  }

}
