.l-site-footer {
  $social-icons-size: 2.5rem;
  $link-opacity: 0.4;

  font-size: 0.75rem;
  font-family: $font-family-sans-serif;

  &,
  .nav-item > a {
    color: $navbar-dark-color;
    text-decoration: none;
  }

  .navbar-brand {
    img {
      height: 5rem;
      max-width: 33vw;
      @include media-breakpoint-up(sm) {
        height: 6.25rem;
      }
    }
  }

  .nav-item > a:hover,
  .nav-item > a:focus,
  .nav-item > a:active, {
    color: $navbar-dark-color;
  }

  .nav-social {
    $padding-x: 0.4375rem;

    margin: 0 (-$padding-x);

    .nav-item {
      padding: 0 $padding-x;
    }

    a {
      background-color: $navbar-dark-color;
      width: $social-icons-size;
      height: $social-icons-size;
      border-radius: $social-icons-size * 0.5;
      opacity: 1;
      transition: $transition-fade;
      &:hover,
      &:focus,
      &.active {
        opacity: $link-opacity;
      }
    }

    .icon {
      width: $social-icons-size * 0.5;
      height: $social-icons-size * 0.5;
    }
  }

  .shop-item {
    .shop-title {
      letter-spacing: $letter-spacing;
      font-weight: 500;
      span {
        color: $dark;
      }
    }
    address {
      a,
      span {
        display: block;
        padding: 0.25rem 0;
        color: $navbar-dark-color;
        letter-spacing: $letter-spacing;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }

  .meta {
    p,
    a {
      letter-spacing: $letter-spacing;
      opacity: $link-opacity;
      padding: 0.312rem 0.625rem;
    }
  }

  hr {
    border-top: solid 1px $navbar-dark-color;
    opacity: $link-opacity;
  }

  .list-bullet {
    li {
      &:after {
        background-color: rgba($navbar-dark-color, $link-opacity);
      }
    }
  }

}
