#widget-intro {
  $breakpoint: "lg";
  $headerHeight: 15rem;


  @include media-breakpoint-down($breakpoint) {
    padding-top: $headerHeight;
  }
  @include media-breakpoint-up($breakpoint) {
    height: 75vh;
    img {
      object-position: top left;
    }
    .container-fluid {
      @include media-breakpoint-up(100rem) {
        max-width: calc(100rem + ((100vw - 100rem) * 0.5));
      }
    }
  }

}
