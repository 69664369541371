.list-bullet {
  display: flex;
  align-items: center;
  li {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      &:after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: $red;
      }
    }
  }
}

.section-mt {
  margin-top: 1.25rem;
  @include media-breakpoint-up(lg) {
    margin-top: 3.75rem;
  }
}

.section-pt-md {
  padding-top: 2.5rem;
  @include media-breakpoint-up(sm) {
    padding-top: 3.75rem;
  }
  @include media-breakpoint-up(md) {
    padding-top: 6.25rem;
  }
}

.section-pb-md {
  padding-bottom: 2.5rem;
  @include media-breakpoint-up(sm) {
    padding-bottom: 3.75rem;
  }
  @include media-breakpoint-up(md) {
    padding-bottom: 6.25rem;
  }
}

.section-pb-lg {
  padding-bottom: 3.75rem;
  @include media-breakpoint-up(sm) {
    padding-bottom: 5rem;
  }
  @include media-breakpoint-up(md) {
    padding-bottom: 7.5rem;
  }
}

.section-pt-lg {
  padding-top: 3.75rem;
  @include media-breakpoint-up(sm) {
    padding-top: 5rem;
  }
  @include media-breakpoint-up(md) {
    padding-top: 7.5rem;
  }
}

.ls-1 {
  letter-spacing: $letter-spacing;
}
