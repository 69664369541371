.btn {
  &.dropdown-toggle {
    .icon {
      margin-left: 1rem;
      stroke-width: 4;
      position: relative;
      top: -2px;
    }

    &:after {
      display: none;
    }
  }

  &.btn-icon-text {
    display: inline-flex;
    align-items: center;
    background-color: $lighter;
    border-radius: $border-radius-pill;
    padding: 0.625rem 1rem 0.625rem 0.625rem;
    border: solid 1px $lighter;

    .svg-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2rem;
      width: 2rem;
      border-radius: 1rem;
      background-color: $white;
      margin-right: 0.9375rem;
      transition: $transition-base;

      .icon {
        stroke-width: 3;
        stroke: $primary;
        fill: transparent;
      }
    }

    .svg-text {
      font-weight: $font-weight-bold;
      letter-spacing: $letter-spacing;
      color: $primary;
      font-size: 1rem;
      text-align: left;
    }

    &:hover,
    &:focus {
      border-color: $lighter;
      background-color: $white;

      .svg-icon {
        background-color: $light;
      }
    }

    &.btn-inverted {
      background-color: $white;
      border-color: $white;
      .svg-icon {
        background-color: $light;
      }
      &:hover,
      &:focus {
        .svg-icon {
          background-color: $primary;

          .icon {
            stroke: $white;
          }
        }
      }
    }
  }
}

@each $name, $value in $theme-colors {
  .btn.btn-inverted.btn-icon-text-#{$name} {
    .svg-icon {
      background-color: $value;
      .icon {
        stroke: $white;
      }
    }
    &:hover,
    &:focus {
      .svg-icon {
        background-color: darken($value, 5%);
      }
    }
  }
}
