.l-site-dropdown {

  $mobile-bp: "sm";
  $mobile-offcanvas-z-index: $zindex-fixed;
  $dropdown-transition: 0.25s ease-in-out;
  $dropdown-delay: 0.15s;

  .nav-link {
    @include media-breakpoint-up($mobile-bp) {
      & ~.l-dropdown-menu {
        width: 100vw;
        max-width: 760px;
        > div {
          border-radius: $border-radius;
          border: solid 1px $white;
        }
      }
      &[aria-expanded='false'] ~ .l-dropdown-menu {
        opacity: 0;
        max-height: 0;
        transition: all 0.5s ease-out;
      }
      &[aria-expanded='true'] ~ .l-dropdown-menu {
        opacity: 1;
        max-height: 1000px;
        transition: all 0.5s ease-in;
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      flex: 0 1 auto;
      a {
        display: block;
        color: $white;
        text-decoration: none;
        letter-spacing: $letter-spacing;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-bold;
        font-size: 1rem;
        padding: 0.625rem;
        &.active {
          color: $red;
        }
        &:hover,
        &:focus  {
          text-decoration: underline;
        }
      }
    }
  }

}
