main#page-faq {
  .list-group {
    .list-group-item {
      border-radius: $list-group-border-radius;
      border: 0;
      transition: $transition-base;
      .svg-wrapper {
        width: 2rem;
        height: 2rem;
        border-radius: 1rem;
        background-color: $light;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 2rem;
        .icon {
          fill: transparent;
          stroke-width: 3;
        }
      }
      + .list-group-item {
        margin-top: 0.625rem;
      }
      &:hover,
      &:active {
        color: $list-group-color;
        padding: $list-group-item-padding-y $list-group-item-padding-x * 2;
      }
    }
  }
}
